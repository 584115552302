import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild
} from '@angular/core';
import {
  faArrowDownToLine,
  faArrowRightArrowLeft,
  faCircleInfo,
  faFloppyDiskCircleArrowRight,
  faTrash
} from '@fortawesome/pro-light-svg-icons';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {IBasicSelectOption} from '../../../shared/interface/ui/form/IBasicSelectOption';
import {ProductsService} from '../../service/product/products.service';
import {catchError, firstValueFrom, Observable, of, Subject, Subscription, throwError, timer} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {EnumTranslateService} from '../../../shared/service/translate/enum-translate.service';
import {noWhitespaceValidator} from '../../../shared/util/form/validators/no-whitespace.validator';
import {hasError, hasRequiredError} from '../../../shared/util/form/form-utils';
import {MetaDataService} from '../../../shared/service/file-utils/meta-data.service';
import {FileType} from '../../../shared/enum/FileType';
import {AssetService} from '../../../asset/service/asset.service';
import * as _ from 'lodash';
import {snakeCase} from 'lodash';
import {minimumOnePlatform} from '../../util/minimum-one-platform.validator';
import {IProductForm} from '../../interface/IProductForm';
import {SnackbarService} from '../../../shared/service/snackbar/snackbar.service';
import {Router} from '@angular/router';
import {ProductMessageType} from '../../enum/ProductMessageType';
import {MessageService} from '../../../shared/service/message/message.service';
import {
  IProductCreatedDialogData,
  ProductCreatedDialogComponent
} from '../product-created-dialog/product-created-dialog.component';
import {BACK_ACTION} from '../../../shared/service/dialog/dialog/dialog-tokens';
import {IBackAction} from '../../../shared/interface/ui/dialog/IBackAction';
import {platformTypeUniqValidator} from '../../util/platform-type-uniq.validator';
import {ICanDeactivateFormCmp} from '../../../shared/interface/ui/form/ICanDeactivateFormCmp';
import {ProductFormTag} from '../../enum/ProductFormTag';
import {ImageDataService} from '../../../shared/service/file-utils/image-data.service';
import {IProductDetails} from '../../interface/IProductDetails';
import {IProductFormGroup} from '../../interface/IProductFormGroup';
import {IPlatformFormGroup} from '../../interface/platform/IPlatformFormGroup';
import {IFormScrollable} from '../../../shared/interface/ui/form/IFormCmp';
import {IFormScrollableHandler} from '../../../shared/interface/ui/form/IFormScrollableHandler';
import {FormScrollableHandler} from '../../../shared/class/FormScrollableHandler';
import {ScreenOrientation} from '../../enum/ScreenOrientation';
import {TranslateService} from '@ngx-translate/core';
import {FileUploadService} from '../../../asset/service/file-upload.service';

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss']
})
export class ProductFormComponent implements OnInit, OnDestroy, ICanDeactivateFormCmp, IFormScrollable, AfterViewChecked {
  @ViewChild('formScrollable') formScrollable: ElementRef;
  private _hasScroll;

  @Input() public isEditMode = false;
  @Input() public initialProductId: string;
  @Input() public isDialog = false;
  @Input() public showProductSavedDialog = false;

  public initialValue: IProductDetails;
  public canDeactivateForm = false;

  public faTrash = faTrash;
  public faArrowDownToLine = faArrowDownToLine;
  public faArrowRightArrowLeft = faArrowRightArrowLeft;
  public faFloppyDiskCircleArrowRight = faFloppyDiskCircleArrowRight;
  public faCircleInfo = faCircleInfo;

  public tags: IBasicSelectOption[] = [];

  public formGroup: FormGroup<IProductFormGroup>;
  public formInitialized = false;

  public avatar: AvatarInput = {
    source: '',
    file: null,
    name: '',
    isEdited: false
  };

  public hasRequiredError: (control: AbstractControl) => boolean = hasRequiredError;
  public hasError: (control: AbstractControl, errorCode: string) => boolean = hasError;

  public closeDialogSubject = new Subject<boolean>();

  public isLoading = false;
  private formScrollableHandler: IFormScrollableHandler;
  private subscriptions: Subscription[] = [];

  constructor(@Optional() @Inject(BACK_ACTION) public backAction: IBackAction,
              private formBuilder: FormBuilder, private productsService: ProductsService,
              private enumTranslateService: EnumTranslateService, private metaDataService: MetaDataService,
              private assetService: AssetService, public changeDetectorRef: ChangeDetectorRef,
              private snackbarService: SnackbarService, private router: Router,
              private messageService: MessageService, private imageDataService: ImageDataService,
              private translateService: TranslateService, private fileUploadService: FileUploadService) {
    this.formScrollableHandler = new FormScrollableHandler(this);
  }

  public ngOnInit(): void {
    this.initData();
  }

  public ngAfterViewChecked(): void {
    this.formScrollableHandler.setScrollStatus();
  }

  private async initData(): Promise<void> {
    await this.initProduct();
    this.initForm();
    this.initAvatar();
    this.initTagOptions().then(() => {
      this.setTags();
    });
    this.observeProductNameChanges();
    this.observeScreenOrientationToggle();
  }

  private async initProduct(): Promise<void> {
    this.initialValue = this.initialProductId ? await this.productsService.getProductByInternalId(this.initialProductId) : null;
  }

  private initForm(): void {
    this.formGroup = this.formBuilder.group({
      id: this.formBuilder.control(this.initialValue?.id ? this.initialValue.id : ''),
      name: this.formBuilder.control(this.initialValue?.name || '',
        [Validators.required, noWhitespaceValidator], [this.productNameUnique.bind(this)]),
      boombitId: this.formBuilder.control(this.initialValue?.boombitId || '', [Validators.required, noWhitespaceValidator],
        [this.boombitIdUnique.bind(this)]),
      tags: this.formBuilder.control([] as IBasicSelectOption[]),
      isPortrait: this.formBuilder.control((this.initialValue?.screenOrientation === ScreenOrientation.PORTRAIT) || !this.isEditMode),
      isLandscape: this.formBuilder.control(this.initialValue?.screenOrientation === ScreenOrientation.LANDSCAPE),
      platforms: this.formBuilder.array([] as FormGroup<IPlatformFormGroup>[], [minimumOnePlatform, platformTypeUniqValidator])
    });
    this.formInitialized = true;
    this.showErrorsIfEdit();
  }

  private showErrorsIfEdit(): void {
    if (this.isEditMode) {
      this.formGroup.markAllAsTouched();
    }
  }

  public async initTagOptions(): Promise<void> {
    this.tags = await this.enumTranslateService.getSelectOptionsFromEnum(ProductFormTag, 'productTags.');
  }

  public setTags(): void {
    if (this.initialValue?.tags?.length > 0) {
      const tagsSelected = this.tags.filter(tagOption => this.initialValue.tags.some(tag => tag === tagOption.name));
      this.formGroup.controls.tags.setValue(tagsSelected);
    }
  }

  private initAvatar(): void {
    this.avatar.source = this.initialValue?.iconUrl || '';
  }

  private observeProductNameChanges(): void {
    const sub = this.formGroup.controls.name.valueChanges.subscribe((value) => {
      this.formGroup.controls.boombitId.setValue(snakeCase(value));
      this.formGroup.controls.boombitId.markAsTouched();
    });
    this.subscriptions.push(sub);
  }

  private observeScreenOrientationToggle(): void {
    const sub1 = this.formGroup.controls.isLandscape.valueChanges.subscribe((value) => {
      this.formGroup.controls.isPortrait.setValue(!value, {emitEvent: false});
    });
    const sub2 = this.formGroup.controls.isPortrait.valueChanges.subscribe((value) => {
      this.formGroup.controls.isLandscape.setValue(!value, {emitEvent: false});
    });
    this.subscriptions.push(sub1, sub2);
  }

  public onSubmitClicked(): void {
    if (!this.isEditMode) {
      this.submitForm();
    }
  }

  public async submitForm(): Promise<void> {
    this.isLoading = true;
    const formValue: IProductForm = _.cloneDeep(this.formGroup.value) as IProductForm;
    const url: string = await this.getAvatarUrl(formValue.boombitId);
    formValue.iconURL = url;
    if (this.isEditMode) {
      await this.updateProduct(formValue);
    } else {
      await this.createProduct(formValue);
    }
  }

  public onCancelClicked(): void {
    if (this.isDialog) {
      this.closeDialogSubject.next(false);
    } else {
      this.router.navigate(['/products/list']);
    }
  }

  public async createProduct(formValue: IProductForm): Promise<void> {
    this.productsService.createProduct(formValue).then(async () => {
      if (!this.shouldPreventCreateSuccessDisplay()) {
        const message = await firstValueFrom(this.translateService.get('product.productCreatedMessage'));
        this.snackbarService.openSuccessSnackbar(message);
      }
      this.handleProductCreated();
    }).catch(() => this.handleApiError());
  }

  private shouldPreventCreateSuccessDisplay(): boolean {
    return this.showProductSavedDialog || !this.isDialog;
  }

  private handleProductCreated(): void {
    if (!this.isDialog) {
      this.canDeactivateForm = true;
      this.router.navigate(['/products/list'], {state: {message: ProductMessageType.CREATED}});
    } else {
      this.handleProductCreatedFromDialog();
    }
  }

  private handleProductCreatedFromDialog(): void {
    if (this.showProductSavedDialog) {
      this.messageService.displayDialog(ProductCreatedDialogComponent, {forCreativesList: true} as IProductCreatedDialogData);
    }
    this.closeDialogSubject.next(true);
  }

  public async updateProduct(formValue: IProductForm): Promise<void> {
    this.productsService.updateProduct(formValue, this.initialValue).then(async () => {
      const message = await firstValueFrom(this.translateService.get('product.productEditedMessage'));
      this.snackbarService.openSuccessSnackbar(message);
      this.closeDialogSubject.next(true);
    }).catch(() => this.handleApiError());
  }

  public handleApiError(): void {
    this.isLoading = false;
  }

  /* Avatar */
  public async onAvatarSelected(event: any, logoInput: HTMLInputElement): Promise<void> {
    if (!(this.metaDataService.getFileType(event.target.files[0]) === FileType.image)) {
      this.deleteAvatar(logoInput);
      return;
    }
    this.avatar.file = event.target.files[0];
    this.avatar.isEdited = true;
    this.readAvatarFile();
    this.avatar.name = await this.assetService.getRandomAssetName(16) + '.' + this.avatar.file.name.split('.').pop();
  }

  public readAvatarFile(): void {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      this.avatar.source = fileReader.result as string;
    };
    fileReader.readAsDataURL(this.avatar.file);
  }

  public deleteAvatar(logoInput: HTMLInputElement): void {
    logoInput.value = '';
    this.avatar.file = null;
    this.avatar.source = '';
    this.avatar.isEdited = true;
  }

  private async handleAvatarUpload(boombitId: string): Promise<string> {
    if (this.fileUploadService.canUploadUsingPresignedUrls()) {
      return await this.handleAvatarUploadUsingPresignedUrls(boombitId);
    } else {
      return await this.handleAvatarUploadUsingEndpoint(boombitId);
    }
  }

  private async handleAvatarUploadUsingEndpoint(boombitId: string): Promise<string> {
    const newFile = new File([this.avatar.file], this.avatar.name, {type: this.avatar.file.type});
    await this.assetService.uploadFile(newFile, boombitId);
    return this.imageDataService.getFileSrcUrl(this.avatar.name, boombitId);
  }

  private async handleAvatarUploadUsingPresignedUrls(boombitId: string): Promise<string> {
    const presignedUrlResponse = await this.assetService.generatePresignedUrls([{originalName: this.avatar.name, boombitId, contentType: this.avatar.file.type}]);
    const fileData = this.assetService.getFileDataPresigned(presignedUrlResponse, this.avatar.name);
    const fileUploadUrl = fileData?.url;
    const newFileName = fileData.generatedName + '.' + this.avatar.name.split('.').pop();
    const newFile = new File([this.avatar.file], newFileName, {type: this.avatar.file.type});
    await firstValueFrom(this.fileUploadService.uploadFileAWSS3(fileUploadUrl, this.avatar.file.type, newFile).pipe(catchError((error) => {
      this.isLoading = false;
      return throwError(() => error);
    })));
    return fileData?.assetUrl;
  }

  public async getAvatarUrl(boombitId: string): Promise<string> {
    if (this.avatar.file) {
      return await this.handleAvatarUpload(boombitId);
    } else if (!this.avatar.isEdited && this.initialValue?.iconUrl) {
      return this.initialValue.iconUrl;
    } else {
      return '';
    }
  }

  /* Validators */
  public productNameUnique(control: AbstractControl): Observable<ValidationErrors | null> {
    if (this.isInitialValue(control.value, 'name')) {
      return of(null);
    } else {
      return timer(1000).pipe(switchMap(async () => {
        const nameExists: boolean = await this.productsService.validateProductName(control.value);
        return nameExists ? {productNameExists: true} : null;
      }));
    }
  }

  public boombitIdUnique(control: AbstractControl): Observable<ValidationErrors | null> {
    if (this.isInitialValue(control.value, 'boombitId')) {
      return of(null);
    } else {
      return timer(1000).pipe(switchMap(async () => {
        const nameExists: boolean = await this.productsService.validateProductBoombitId(control.value);
        return nameExists ? {productBoombitIdExists: true} : null;
      }));
    }
  }

  private isInitialValue(value: any, field: keyof IProductDetails): boolean {
    return this.initialValue && this.initialValue[field] && this.initialValue[field] === value;
  }

  public get productBoombitId(): string {
    return this.formGroup.controls.boombitId.value;
  }


  public get hasScroll(): boolean {
    return this._hasScroll;
  }

  public set hasScroll(value: boolean) {
    this._hasScroll = value;
  }

  public ngOnDestroy(): void {
    this.subscriptions?.forEach(sub => sub?.unsubscribe());
  }
}

interface AvatarInput {
  file: File;
  isEdited: boolean;
  name: string;
  source: string;
}

